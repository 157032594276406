@import "src/assets/css/variables";

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  font-family: $font-default;
  color: $default;
  background-color: #dbdbdb;
}

a {
  color: $primary;
}

a:hover {
  color: lighten($primary, 10);
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font-primary;
}

.back-to-previous {
  i {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    background: $primary;
    color: $secondary;
    transition: all 0.4s;

    &:hover {
      background: $secondary;
      color: $primary;
    }
  }

}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  display: none;
  right: 15px;
  bottom: 15px;
  z-index: 99999;

  i {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    background: $primary;
    color: $secondary;
    transition: all 0.4s;

    &:hover {
      background: $secondary;
      color: $primary;
    }
  }

}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: $secondary;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 0px);
  left: calc(50% - 30px);
  border: 6px solid $primary;
  border-top-color: $secondary;
  border-bottom-color: $secondary;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: animate-preloader 1s linear infinite;
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/

/* Desktop Navigation */
.nav-menu {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  > ul {
    display: flex;
  }

  > ul > li {
    position: relative;
    white-space: nowrap;
    padding: 10px 0 10px 28px;
  }

  a {
    display: block;
    position: relative;
    color: $white;
    transition: 0.3s;
    font-size: 15px;
    font-family: $font-default;
    font-weight: 600;
  }

  a:hover, .active > a, li:hover > a {
    color: $primary;
  }

}

@media (max-width: 1366px) {
  .nav-menu .drop-down {

    .drop-down ul {
      left: -90%;
    }

    .drop-down:hover > ul {
      left: -100%;
    }

    .drop-down > a:after {
      content: "\ea9d";
    }
  }
}


/* Mobile Navigation */
.navbar-toggler:focus, .navbar-toggler:active, .navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
}

nav button {
  border-color: rgb(255, 255, 255);
}

nav button .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='3' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

nav button:hover {
  border-color: $primary;
}

  /*--------------------------------------------------------------
  # Sections General
  --------------------------------------------------------------*/
  section {
    padding: 60px 0;
    overflow: hidden;
  }

  .section-title {

    h2 {
      font-size: 25px;
      font-weight: 700;
      padding: 0;
      line-height: 1px;
      margin: 0 0 8px 0;
      letter-spacing: 2px;
      text-transform: uppercase;
      color: lighten($default, 40);
      font-family: $font-secondary;
    }

    h2::after {
      content: "";
      width: 120px;
      height: 1px;
      display: inline-block;
      background: lighten($primary, 15);
      margin: 4px 10px;
    }

    p {
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase;
      font-family: $font-secondary;
      color: $secondary;
    }

  }

  /*--------------------------------------------------------------
  # Breadcrumbs
  --------------------------------------------------------------*/
  .breadcrumbs {
    padding: 15px 0;
    background: lighten($secondary, 88);
    min-height: 40px;
    margin-top: 74px;

    h2 {
      font-size: 28px;
      font-weight: 400;
    }

    ol {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      padding: 0;
      margin: 0;

      li + li {
        padding-left: 10px;
      }

      li + li::before {
        display: inline-block;
        padding-right: 10px;
        color: lighten($secondary, 10);
        content: "/";
      }
    }

    @media (max-width: 992px) {
      margin-top: 68px;
      .d-flex {
        display: block !important;

      }

      ol {
        display: block;

        li {
          display: inline-block;
        }

      }
    }
  }


