/* You can add global styles to this file, and also import other style files */

@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "~aos/dist/aos.css";
@import "~remixicon/fonts/remixicon.css";
@import "~boxicons/css/boxicons.min.css";
@import "~venobox/venobox/venobox.min.css";
@import "assets/css/variables";
@import "assets/css/styles.scss";
